import {
  Button,
  Center,
  Container,
  Group,
  Paper,
  Stack,
  Text,
  ThemeIcon,
  Title,
} from '@mantine/core'
import { IconCircleCheck } from '@tabler/icons-react'
import React from 'react'
import { hooks } from '~/client/lib/hooks'

interface NoCorpProps {
  title: string
  message: string
  onRequestAccess: () => void
  onAddCorp: () => void
  isSuccess: boolean
}

export const NoCorp: React.FC<NoCorpProps> = ({
  title,
  message,
  isSuccess,
  onRequestAccess,
  onAddCorp,
}) => {
  const userEmail = hooks.useAuthStore((state) => state.user?.email)

  return (
    <Center>
      <Paper>
        <Container size='sm' px='sm'>
          <Stack mt='xl' align='center'>
            <Title order={2} ta='center' p='md'>
              {title}
            </Title>
            <Text c='dimmed' ta='center'>
              {message} If you believe this is a mistake, please check you are logged in as the
              correct user (<a href={`mailto:${userEmail}`}>{userEmail}</a>), create a company, or
              contact us to request access to an existing company.
            </Text>
            <Group>
              {isSuccess ? (
                <Button
                  size='lg'
                  disabled
                  leftSection={
                    <ThemeIcon color='go' display='contents'>
                      <IconCircleCheck />
                    </ThemeIcon>
                  }
                >
                  Request sent to Aerial
                </Button>
              ) : (
                <Button size='lg' onClick={() => onRequestAccess()}>
                  Request access
                </Button>
              )}
              <Button size='lg' onClick={onAddCorp}>
                Create corp
              </Button>
            </Group>
          </Stack>
        </Container>
      </Paper>
    </Center>
  )
}
