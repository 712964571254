import type { NextPage } from 'next'
import Router from 'next/router'
import { CorpSelect } from '~/client/components/corp-select'
import { AerialAppShell, AppHeader } from '~/client/components/layout'
import { Signup } from '~/client/components/login'
import { LoginRequired } from '~/client/components/protections'
import { mkCorpRoute } from '~/common/util'

const Page: NextPage = () => {
  return (
    <LoginRequired unauthenticatedComponent={<Signup />}>
      <AerialAppShell header={<AppHeader noCorpUrl />}>
        <CorpSelect onSelect={(corp) => Router.push(mkCorpRoute(corp.cryptId, 'dashboard'))} />
      </AerialAppShell>
    </LoginRequired>
  )
}

export default Page
