import { ActionIcon, Card, Center, SimpleGrid, Stack, Text, Title } from '@mantine/core'
import { useHover } from '@mantine/hooks'
import { showNotification } from '@mantine/notifications'
import { IconPlus } from '@tabler/icons-react'
import React, { useState } from 'react'
import { NewCorpModal, WelcomeNewCorpModal, useNewCorp } from '~/client/components/modals'
import { NextLinkOpt } from '~/client/components/util'
import { LoadingErrorComp } from '~/client/components/util/error'
import { LoadMoreInfiniteQuery } from '~/client/components/util/load-more-infinite-query'
import { SearchInputWithLoading } from '~/client/components/util/search-input-with-loading'
import { hooks } from '~/client/lib/hooks'
import { useCorpSearch } from '~/client/lib/hooks/search'
import { theme } from '~/client/lib/theme'
import { AugmentedMetadataDate, type ZAugmentedQueryCorpsItem } from '~/common/schema'
import { NoCorp } from './no-corp'
import { RequestAccessModal } from './request-access-modal'

interface CorpSelectBaseProps {
  onSelect: (corp: ZAugmentedQueryCorpsItem) => void
}

interface CorpCardProps extends CorpSelectBaseProps {
  corp: ZAugmentedQueryCorpsItem
}

const CorpCardLayout: React.FC<{ onClick?: () => void }> = ({ children, onClick }) => {
  const { ref, hovered } = useHover()

  return (
    <Card
      ref={ref}
      shadow={hovered ? 'md' : 'sm'}
      p='lg'
      radius='md'
      withBorder
      onClick={onClick}
      style={{
        cursor: 'pointer',
        minWidth: 200,
        minHeight: 200,
      }}
    >
      <Stack align='center' justify='center' h='100%'>
        {children}
      </Stack>
    </Card>
  )
}

const CorpCard: React.FC<CorpCardProps> = ({ onSelect, corp }) => {
  return (
    <CorpCardLayout onClick={() => onSelect(corp)} key={corp.name.value}>
      <Title
        order={3}
        ta='center'
        // This is workaround for lineClamp:3 for Html Heading element
        style={{
          WebkitLineClamp: 3,
          display: '-webkit-box',
          overflow: 'hidden',
          WebkitBoxOrient: 'vertical',
        }}
      >
        {corp.name.value}
      </Title>
      <Text c='dimmed'>{AugmentedMetadataDate.display(corp.startDate?.value)}</Text>
    </CorpCardLayout>
  )
}

const NewCorpCard: React.FC<{ onAddCorp: () => void }> = ({ onAddCorp }) => {
  return (
    <>
      {/* Actually, this will never be loading because we batch the search with the load user query */}
      <CorpCardLayout onClick={onAddCorp}>
        <ActionIcon color='primary' radius='xl' size='xl' variant='subtle'>
          <IconPlus size='100%' />
        </ActionIcon>
        <Title order={4} ta='center' c='primary'>
          Add Corp
        </Title>
      </CorpCardLayout>
    </>
  )
}

type RequestAccessState = { onSuccess?: () => void } | null

interface CorpSelectProps extends CorpSelectBaseProps {
  limit?: number
}

/**
 * Wraps useNewCorp to open the modal on first load if the user has no corp and no query
 * @param hasNoCorp
 * @param query
 * @returns
 */
const useWelcomeNewCorpHook = (hasNoCorp: boolean, query: string) => {
  const [firstLoad, setFirstLoad] = React.useState(true)
  const welcomeNewCorpProps = useNewCorp()

  const { openModal } = welcomeNewCorpProps

  React.useEffect(() => {
    if (hasNoCorp && query === '' && firstLoad) {
      openModal()
      setFirstLoad(false)
    }
  }, [hasNoCorp, openModal, firstLoad, query])

  return welcomeNewCorpProps
}

export const CorpSelect: React.FC<CorpSelectProps> = ({ onSelect, limit = 16 }) => {
  const newCorpProps = useNewCorp()

  const { query, setQuery, searchResults, hasNoCorp } = useCorpSearch({
    queryWithEmptyString: true,
    limit,
    sortField: '_id',
    direction: -1,
  })

  const [requestAccessState, setRequestAccessState] = useState<RequestAccessState>(null)
  const sendRequestAccessEmail = hooks.trpc().sendRequestAccessEmail.useMutation({
    onSuccess: () => {
      requestAccessState?.onSuccess?.()
    },
  })

  const openRequestAccessModal = (onSuccess?: () => void) => setRequestAccessState({ onSuccess })

  const corps = React.useMemo(() => {
    const arr = searchResults.data?.pages.flatMap((page) => page.data)
    if (!arr) return []
    // Hide the last element to fit the "Add Corp" button
    // Changing the query limit on the initial query didn't work, because pagination restarts when the input changes
    return searchResults.hasNextPage ? arr.slice(0, arr.length - 1) : arr
  }, [searchResults.data?.pages, searchResults.hasNextPage])

  // If user only has one corp (without searching), select it automatically.
  if (query === '' && corps.length === 1) {
    const corp = corps[0]
    if (!corp) throw new Error('Should never happen')
    onSelect(corp)
  }

  const cardsCount = corps.length + 1
  // Center corps if the number of corps is smaller than the number of columns
  const getCols = (maxCols: number) => (cardsCount < maxCols ? cardsCount : maxCols)

  const welcomeNewCorpProps = useWelcomeNewCorpHook(hasNoCorp, query)

  // Indicates found no corp matching query
  const foundNoCorp =
    searchResults.data?.pages.reduce((total, page) => total + page.data.length, 0) === 0

  return (
    <Center data-testid='corp-select-comp'>
      <Stack mt='xl' align='center'>
        <Title order={2} ta='center' p='md'>
          Select Company
        </Title>
        <Center>
          <SearchInputWithLoading
            w='320px'
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => setQuery(e.target.value)}
            isFetching={searchResults.isFetching}
            placeholder='Search'
          />
        </Center>
        <LoadingErrorComp queryResult={searchResults}>
          {hasNoCorp ? (
            <NoCorp
              onAddCorp={welcomeNewCorpProps.openModal}
              title='No Access'
              message='You do not have access to any companies on Aerial.'
              onRequestAccess={() => openRequestAccessModal()}
              isSuccess={sendRequestAccessEmail.isSuccess}
            />
          ) : foundNoCorp && query !== '' ? (
            <NoCorp
              title='No Results'
              message={`No companies found that match your query "${query}".`}
              onAddCorp={newCorpProps.openModal}
              onRequestAccess={() => openRequestAccessModal()}
              isSuccess={sendRequestAccessEmail.isSuccess}
            />
          ) : (
            <Stack align='center'>
              <SimpleGrid
                cols={{ base: 1, sm: getCols(2), md: getCols(3), lg: getCols(4) }}
                py='lg'
                px='sm'
                style={{ maxWidth: theme.other.widths.lg }}
              >
                {corps.map((corp) => (
                  <CorpCard key={corp.cryptId.idStr} corp={corp} onSelect={onSelect} />
                ))}
                <NewCorpCard onAddCorp={newCorpProps.openModal} />
              </SimpleGrid>
              {searchResults.hasNextPage && <LoadMoreInfiniteQuery query={searchResults} />}
              <Center>
                <Text c='dimmed' size='sm' mt='lg'>
                  Cannot find the company you’re looking for?{' '}
                  <NextLinkOpt
                    onClick={() =>
                      openRequestAccessModal(() =>
                        showNotification({ message: 'Access request sent!', color: 'go' })
                      )
                    }
                  >
                    Contact us
                  </NextLinkOpt>{' '}
                  to request access to a company.
                </Text>
              </Center>
            </Stack>
          )}
        </LoadingErrorComp>
      </Stack>
      <RequestAccessModal
        requestAccessMutation={sendRequestAccessEmail}
        onClose={() => setRequestAccessState(null)}
        opened={!!requestAccessState}
      />
      <NewCorpModal {...newCorpProps} />
      <WelcomeNewCorpModal {...welcomeNewCorpProps} />
    </Center>
  )
}
