import type { ModalProps } from '@mantine/core'
import { Button, Group, Loader, Stack, TextInput, Textarea } from '@mantine/core'
import { useForm, zodResolver } from '@mantine/form'
import type { UseMutationResult } from '@tanstack/react-query'
import React from 'react'
import { BaseModal } from '~/client/components/modals'
import { zIndex } from '~/client/components/z-index'
import { hooks } from '~/client/lib/hooks'
import { theme } from '~/client/lib/theme'
import { ZRequestAccessForm } from '~/common/schema'

type RequestAccessMutation = UseMutationResult<void, unknown, ZRequestAccessForm>

interface RequestAccessModalProps extends ModalProps {
  requestAccessMutation: RequestAccessMutation
}

export const RequestAccessModal: React.FC<RequestAccessModalProps> = ({
  requestAccessMutation,
  ...props
}) => {
  const user = hooks.useAuthStore((state) => state.user)

  const form = useForm<ZRequestAccessForm>({
    initialValues: {
      name: user?.displayName ?? '',
      message: '',
    },
    validate: zodResolver(ZRequestAccessForm),
  })

  return (
    <BaseModal {...props} zIndex={zIndex.modal} title='Request Access to Company' size={500}>
      <form
        onSubmit={form.onSubmit(async (values) => {
          await requestAccessMutation.mutateAsync(values)
          props.onClose()
        })}
      >
        <Stack style={{ gap: theme.spacing.xs }}>
          <TextInput required label='Name' {...form.getInputProps('name')} />
          <TextInput required label='Email' disabled value={user?.email ?? ''} />
          <Textarea
            label='Company to access'
            placeholder='I would like access to company ...'
            {...form.getInputProps('message')}
          />
          <Group align='center' mt='md' style={{ justifyContent: 'flex-end' }}>
            {requestAccessMutation.isLoading && <Loader size='sm' />}
            <Button
              disabled={requestAccessMutation.isLoading}
              type='submit'
              style={{ alignSelf: 'end' }}
            >
              Submit
            </Button>
          </Group>
        </Stack>
      </form>
    </BaseModal>
  )
}
